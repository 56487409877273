import { createRouter, createWebHashHistory, createWebHistory } from "vue-router";
import { store } from "@/store";
const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "login",
    component: () => import(/*webpackChunkName:'login'*/ "@/views/login.vue"),
  },
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/home.vue"),
    children: [
      // 账号管理
      {
        path: "/c72fdcc7-0c14-4bd7-8743-b801967565e1",
        name: "c72fdcc7-0c14-4bd7-8743-b801967565e1",
        component: () => import("@/views/account/sysUser.vue"),
      },
      {
        path: "/f8cda1bf-bf6f-4fa5-9775-72f083a8dece",
        name: "f8cda1bf-bf6f-4fa5-9775-72f083a8dece",
        component: () => import("@/views/account/merchant.vue"),
      },
      // 下发管理
      {
        path: "/ce723996-c90b-4769-9420-f734da0bb718",
        name: "ce723996-c90b-4769-9420-f734da0bb718",
        component: () => import("@/views/channel/channelBank.vue"),
      },
      // 订单管理
      {
        path: "/d3e0899f-c106-4a60-acc7-6e9da44e93b2",
        name: "d3e0899f-c106-4a60-acc7-6e9da44e93b2",
        component: () => import("@/views/order/order.vue"),
      },
      // 通道对账
      {
        path: "/83e6e616-9a48-4eab-bae0-16abbe14f581",
        name: "83e6e616-9a48-4eab-bae0-16abbe14f581",
        component: () => import("@/views/statistic/channel.vue"),
      },
      // 商户对账
      {
        path: "/83e6e616-9a48-4eab-bae0-16abbe14f58e",
        name: "83e6e616-9a48-4eab-bae0-16abbe14f58e",
        component: () => import("@/views/statistic/merchant.vue"),
      },
    ],
  },
];

const router = createRouter({
  // hash模式.适合后台,不要seo
  history: createWebHashHistory(process.env.BASE_URL),
  // h5模式 适合seo
  // history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to, from, next) => {
  if (store.getters.GetToken) {
    if (to.path === "/login") {
      next("/home");
    } else {
      next();
    }
  } else {
    if (to.path === "/login") {
      next();
    } else {
      next("/login");
    }
  }
});
export default router;
