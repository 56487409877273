import { createStore } from "vuex";
import router from "@/router";
import { User } from "@/api";

const AUTH_TOKEN_KEY = "token";
const AUTH_MENU_KEY = "Menu";
const USER_INFO = "UserInfo";

const state = {
  toggleSwitch: false,
};
const getters = {
  GetToken: (state) => localStorage.getItem(AUTH_TOKEN_KEY),
  GetUserInfo: (state) => JSON.parse(localStorage.getItem(USER_INFO)),
  GetMenuList: (state) => JSON.parse(localStorage.getItem(AUTH_MENU_KEY)),
  GetCollapse: (state) => state.toggleSwitch,
};
const mutations = {
  SetToken: (state, value) => localStorage.setItem(AUTH_TOKEN_KEY, value),
  SetUserInfo: (state, value) => localStorage.setItem(USER_INFO, value),
  SetMenu: (state, value) => localStorage.setItem(AUTH_MENU_KEY, value),
  SetToggle: (state) => (state.toggleSwitch = !state.toggleSwitch),
};
const actions = {
  login: async ({ commit }, data) => {
    let resBody = await User.Login(data);
    if (!resBody) return;
    if (!resBody[AUTH_MENU_KEY]) return;
    if (!resBody[USER_INFO]) return;
    commit("SetMenu", JSON.stringify(resBody[AUTH_MENU_KEY]));
    commit("SetUserInfo", JSON.stringify(resBody[USER_INFO]));
    router.push("/home");
  },
  logout: async ({ commit }, call) => {
    if (call) {
      await User.Logout();
    }
    localStorage.clear();
    router.push("/login");
  },
  toggle: ({ commit }) => commit("SetToggle"),
};

const store = createStore({
  state,
  getters,
  mutations,
  actions,
});

export { store };
