import { Download, POST } from "./request";

/// 通用个人操作模块
export const User = {
  Login: (data, isLoading = true) => {
    return POST("/admin/v1/user/login", data, isLoading);
  },
  Logout: (data, isLoading = true) => {
    return POST("/admin/v1/user/logout", data, isLoading);
  },
  ResetPass: (data, isLoading = true) => {
    return POST("/admin/v1/user/reset-pwd", data, isLoading);
  },
  ResetCaptcha: (data, isLoading = true) => {
    return POST("/admin/v1/user/reset-captcha", data, isLoading);
  },
};
// ************************************************公共数据接口*******************************************************
export const DataDic = {
  // 获取银行列表
  RoleItem: () => {
    return POST("/admin/v1/dic/role-item", {}, false);
  },
  BankTypeItem: () => {
    return POST("/admin/v1/dic/bank-type-item", {}, false);
  },
  BankCodeItem: () => {
    return POST("/admin/v1/dic/bank-code-item", {}, false);
  },
  OrderState: () => {
    return POST("/admin/v1/dic/order-state", {}, false);
  },
};

// ************************************************账号管理*******************************************************
//  ****************系统账号管理****************
export const Admin = {
  // 系统账号列表
  List: (data) => {
    return POST("/admin/v1/account/system/list", data);
  },
  // 添加系统账号
  Add: (data) => {
    return POST("/admin/v1/account/system/add", data);
  },
  // 修改系统账号
  Edit: (data) => {
    return POST("/admin/v1/account/system/edit", data);
  },
};
//  ****************商户管理****************
export const Merchant = {
  API: (data) => {
    return Download("/admin/v1/account/merchant/download-api", data);
  },
  // 商户列表
  List: (data) => {
    return POST("/admin/v1/account/merchant/list", data);
  },
  // 添加商户
  Add: (data) => {
    return POST("/admin/v1/account/merchant/add", data);
  },
  // 修改商户
  Edit: (data) => {
    return POST("/admin/v1/account/merchant/edit", data);
  },
  // 商户充值
  Recharge: (data) => {
    return POST("/admin/v1/account/merchant/recharge", data);
  },
};
// 银行卡
export const ChannelBank = {
  MList: (data) => {
    return POST("/admin/v1/channel/bank/mer-list", data);
  },
  List: (data) => {
    return POST("/admin/v1/channel/bank/list", data);
  },
  Add: (data) => {
    return POST("/admin/v1/channel/bank/add", data);
  },
  Edit: (data) => {
    return POST("/admin/v1/channel/bank/edit", data);
  },
  Remove: (data) => {
    return POST("/admin/v1/channel/bank/remove", data);
  },
};
//  ****************订单查看****************
export const Order = {
  // 订单列表
  List: (data) => {
    return POST("/admin/v1/order/list", data);
  },
};
// ************************************************对账*******************************************************
// 商户对账
export const StatisticsMerchant = {
  SearchAll: (data) => {
    return POST("/admin/v1/statistic/merchant/search-all", data);
  },
};
// 供应商对账
export const StatisticsChannel = {
  SearchAll: (data) => {
    return POST("/admin/v1/statistic/channel/search-all", data);
  },
};
